import React from "react";
import weeklyWeatherPicture from "../../assets/projects/weekly-weather.JPG";

let projects = [
  {
    title: "Weekly Weather",
    description:
      "A weather app that displays the current weather and a 5 day forecast for a city of your choice. The app uses the OpenWeather API to retrieve weather data for cities. The app also uses local storage to store the last city searched for and displays it on the page. The app also displays the UV index for the current day. The UV index is color coded to indicate whether the conditions are favorable, moderate, or severe. The app also displays weather icons to indicate the weather conditions for the current day and the 5 day forecast.",
    image: weeklyWeatherPicture,
    link: "https://derekbeckford.github.io/weather-weekly/",
    github: "https://github.com/derekBeckford/weather-weekly",
  },
];
function Portfolio() {
  return (
    <section id="work" className="container-wrapper">
      {projects.map((project) => (
        <div className="container">
          <a href={project.link}>
            <img
              src={project.image}
              className="project"
              alt="Project Weekly Weather"
            />
            <div class="image_overlay">
              <div class="image_title">{project.title}</div>
              <a href={project.link}>Link</a>
              <a href={project.github}>GitHub</a>
            </div>
          </a>
        </div>
      ))}
    </section>
  );
}

export default Portfolio;
