import React from "react";
import resume from "../../assets/resume/Resume_DB.pdf";

function Resume() {
  return (
    <span>
      <iframe src={resume} className="resume" title="resume"></iframe>
    </span>
  );
}

export default Resume;
