import React from "react";

function About() {
  return (
    <section id="aboutme" class="aboutme">
      <h2>About Me</h2>
      <p>
        Hello! I'm Derek Beckford, a Quality Assurance Engineer and seasoned
        mentor based in Austin, Texas. With a background in multiple languages
        such as HTML5, CSS3, JavaScript ES6+, TypeScript, SQL, and Python, and
        proficiency in applications and tools like MongoDB, MySQL, Heroku,
        Express, React, Node, Bootstrap, GraphQL, Mongoose, Jest, Cypress.io,
        Mocha, Postman, and Chai, I bring a robust and diverse skill set to any
        tech team. After gaining a wealth of experience in the healthcare sector
        as a Medical Social Worker, I pivoted into the technology industry where
        my strong interpersonal and communication skills became an asset in my
        tech-oriented roles. Most recently, I served as a QA Engineer at
        Atmosphere TV, leading in the development and maintenance of automated
        test scripts. While I have completed my tenure with Atmosphere TV, I am
        actively seeking full-time opportunities where I can contribute my
        expertise and continue my growth in the tech industry. Alongside my
        pursuit for a new role, I continue to provide mentorship as a Teaching
        Assistant for the University of Texas at Austin Web Development
        Bootcamp, sharing the knowledge I've gained while continually refining
        my own technical skills. Away from the professional sphere, I'm an
        advocate for the LGBTQIA+ community. In my personal time, I'm an avid
        weight lifter and axe throwerr If you'd like to connect, collaborate, or
        if you're aware of opportunities where my skills and experiences could
        be a fit, please don't hesitate to reach out to me on LinkedIn or
        GitHub. I'm looking forward to engaging in new challenges and bringing
        my experience to bear on the next exciting project.
      </p>
    </section>
  );
}

export default About;
